import { useQuery } from '@tanstack/react-query';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import ls from 'localstorage-slim';
import { useEffect } from 'react';
import {
  type SubscanReward,
  fetchSubscanRewardsWithoutRetry,
} from 'services/subscan';

import { api } from 'lib/trpc/react';

const ttl = 600;

type Props = {
  cutoffTimestamp: number;
  address?: string;
};

type UseStakingRewardsResult = {
  isLoading: boolean;
  rewards: SubscanReward[] | undefined;
  hasFailed: boolean;
};

export function useStakingRewardsFromSubscanAndBackend({
  cutoffTimestamp,
  address,
}: Props): UseStakingRewardsResult {
  const { key, subscanApiUrl } = useMoonChain();
  const cacheKey = `${subscanApiUrl}${address}`;
  const cachedRewards = ls.get<SubscanReward[]>(cacheKey);

  // 1. First sending fetch request to subscan without API KEY
  const fetchResult = useQuery({
    queryKey: ['staking-rewards', address],
    queryFn: () =>
      fetchSubscanRewardsWithoutRetry(address as string, key, cutoffTimestamp),
    enabled: !cachedRewards && !!address && !!key,
  });

  // 2. If the first request fails, send another request with API KEY(BACKEND ONLY)
  const trpcResult = api.staking.getStakingRewards.useQuery(
    {
      moonChainKey: key,
      cutoffTimestamp,
      address: address as string,
    },
    {
      enabled:
        !!key &&
        !!address &&
        !cachedRewards &&
        !fetchResult.data &&
        !fetchResult.error,
      staleTime: 30000,
    },
  );

  useEffect(() => {
    if (trpcResult.data && !cachedRewards) {
      ls.set(cacheKey, trpcResult.data, { ttl: ttl });
    }
  }, [trpcResult.data, cacheKey, cachedRewards]);

  useEffect(() => {
    if (fetchResult.data && !cachedRewards) {
      ls.set(cacheKey, fetchResult.data, { ttl: ttl });
    }
  }, [fetchResult.data, cacheKey, cachedRewards]);

  return {
    rewards: cachedRewards || fetchResult.data || trpcResult.data,
    isLoading: fetchResult.isLoading || trpcResult.isLoading,
    hasFailed: fetchResult.isError || trpcResult.isError,
  };
}
