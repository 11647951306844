import { Card, Center, ScrollArea, Stack, Transition } from '@mantine/core';
import { StakingRewardRow } from 'components/BalanceGrid/StakingRewards/StakingRewardRow';
import { OffsiteLink } from 'components/OffsiteLink';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { SubscanReward } from 'services/subscan';

interface Props {
  show: boolean;
  address?: string;
  latestRewards: SubscanReward[];
}

export const StakingRewardsList = ({ show, address, latestRewards }: Props) => {
  const { subscanUrl } = useMoonChain();

  const rows = latestRewards.map((reward, i) => (
    <StakingRewardRow reward={reward} key={reward.block_timestamp} index={i} />
  ));

  return (
    <Transition
      mounted={show}
      transition={'slide-left'}
      duration={400}
      timingFunction={'ease'}
    >
      {(styles) => (
        <Card withBorder style={styles} hidden={!show} pb={6} pr={6}>
          <Stack gap={'xs'}>
            <ScrollArea h={234} pt={0} scrollbarSize={6}>
              {rows}
            </ScrollArea>
            <Center>
              <OffsiteLink
                link={`${subscanUrl}/account/${address}?tab=reward`}
                label={'Go to Subscan'}
              />
            </Center>
          </Stack>
        </Card>
      )}
    </Transition>
  );
};
